// import { BiSortDown, BiSortUp, BiSort } from 'react-icons/bi';
import { format } from 'date-fns';
import React, { Fragment, useEffect, useState } from 'react';
import { GoVerified } from 'react-icons/go';
import {
  NavigateFunction,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { Button, Input, Modal, ModalBody, ModalHeader } from 'reactstrap';
import '../assets/css/table.css';
import Paginator from '../components/Paginator';
import Searcher from '../components/Searcher';
import MachineService from '../services/MachineService';
import { APIResponse, Machine } from '../types';

function MachinesTable() {
  const [reload, setReload] = useState(false);
  const [machines, setMachines] = useState<APIResponse<Machine>>();
  const [showEntriesNum, setShowEntriesNum] = useState(10);
  const [modal, setModal] = useState(false);
  const { num } = useParams();
  const page = parseInt(num || '1');
  const navigate: NavigateFunction = useNavigate();
  const searchParams = useSearchParams()[0].toString();

  const [columns, setColumns] =
    useState<{ name: string; ref: string; show: boolean }[]>();

  useEffect(() => {
    if (sessionStorage.getItem('columns')) {
      setColumns(JSON.parse(sessionStorage.getItem('columns') ?? ''));
    } else {
      setColumns([
        { name: 'Brand', ref: 'plain_brand', show: true },
        { name: 'Model', ref: 'plain_model', show: true },
        { name: 'Year', ref: 'manufacturing_year', show: true },
        { name: 'Price', ref: 'price', show: true },
        { name: 'Source', ref: 'source.name', show: true },
        { name: 'Condition', ref: 'condition', show: false },
        { name: 'Autocrawled', ref: 'auto_crawled', show: false },
        { name: 'Date', ref: 'last_updated', show: false },
        { name: 'Seller', ref: 'plain_seller', show: false },
        { name: 'Created at', ref: 'created', show: false },
      ]);
    }

    MachineService.getFilteredMachines(page, showEntriesNum).then((data) => {
      setMachines(data);
      setReload(false);
    });
  }, [page, showEntriesNum, reload]);

  const openMachineView = (id: string | number) => {
    navigate('/machine/' + id + '/view/', {
      state: { backURL: window.location.href },
    });
  };

  const handleField = (e: React.FormEvent) => {
    setColumns((prevState) => {
      const currentState = prevState ? [...prevState] : [];

      const objIndex = currentState.findIndex(
        (col) => col.ref === (e.target as HTMLFormElement).id
      );

      if (objIndex !== -1) {
        const updatedObj = {
          ...currentState[objIndex],
          show: !currentState[objIndex].show,
        };
        currentState[objIndex] = updatedObj;
      }

      sessionStorage.setItem('columns', JSON.stringify(currentState));

      return currentState;
    });
    sessionStorage.setItem('columns', JSON.stringify(columns));
  };

  const toggle = () => setModal(!modal);

  if (machines === undefined) {
    return (
      <Fragment>
        <div className="loader"></div>
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <Searcher setReload={setReload} />
        <div className="machines_table-container">
          <div className="row">
            <div className="w-1/2 btn-modal-add_machines">
              <Button color="secondary" onClick={toggle}>
                Show/Hide columns
              </Button>
              <Modal
                isOpen={modal}
                toggle={toggle}
                className="modal-add_machines"
              >
                <ModalHeader toggle={toggle}>Available fields</ModalHeader>
                <ModalBody>
                  {columns
                    ? columns.map((column, i) => (
                        <Button
                          key={i}
                          className={
                            column.show
                              ? 'field-selector field-selected'
                              : 'field-selector'
                          }
                          id={column.ref}
                          onClick={handleField}
                        >
                          {column.name}
                        </Button>
                      ))
                    : null}
                </ModalBody>
              </Modal>
            </div>
            {machines && (
              <div className="w-1/2 entries_info ml-auto">
                Showing{' '}
                {machines.count === 0 ? 0 : (page - 1) * showEntriesNum + 1} to{' '}
                {machines.count < (page - 1) * showEntriesNum + showEntriesNum
                  ? machines.count
                  : (page - 1) * showEntriesNum + showEntriesNum}{' '}
                of {machines.count} entries
              </div>
            )}
          </div>
          <table className="min-w-full bg-white border border-gray-200 mb-4">
            <thead>
              <tr>
                {columns &&
                  columns.map((col, i) => {
                    return (
                      col.show && (
                        <th className="border py-3 px-4" key={i}>
                          <div className="th-container">{col.name}</div>
                        </th>
                      )
                    );
                  })}
              </tr>
            </thead>
            <tbody>
              {machines.results.map((machine: Machine, i) => {
                return (
                  <tr
                    className="cursor-pointer hover:bg-gray-200"
                    key={i}
                    onClick={() => openMachineView(machine.id)}
                  >
                    {columns &&
                      columns.map((col, i) => {
                        return (
                          col.show && (
                            <td
                              className="border py-3 px-4 max-w-0 overflow-hidden text-ellipsis"
                              key={i}
                            >
                              {machine[col.ref] === true ? (
                                <GoVerified />
                              ) : col.ref === 'source.name' ? (
                                machine.source?.name
                              ) : col.ref === 'created' ? (
                                format(
                                  Date.parse(machine[col.ref]),
                                  'dd MMM yyyy'
                                )
                              ) : (
                                machine[col.ref]
                              )}
                            </td>
                          )
                        );
                      })}
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="row selector_paginator-container">
            <div className="show_entries-selector col-md-3">
              <span>Show </span>
              <Input
                type="select"
                bsSize="sm"
                onChange={(event) => {
                  setShowEntriesNum(parseInt(event.target.value));
                  navigate('/machines/page/' + 1 + searchParams);
                }}
              >
                <option>10</option>
                <option>25</option>
                <option>50</option>
                <option>100</option>
              </Input>{' '}
              entries
            </div>
            <div className="col-md-4"></div>
            <div className="pagination-container col-md-5">
              <Paginator
                page={page}
                history={navigate}
                showEntriesNum={showEntriesNum}
                machinesCount={machines.count}
              />
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default MachinesTable;
